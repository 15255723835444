$('#getstarted').click(function(){
	if($('#cmessage').attr('data-type') == 'buying')
	{
		$('#cmessage').val('I am interested in buying a home.');
	}
	else
	{
		$('#cmessage').val('I am interested in selling a home.');
	}
	$('#contactusmodal').modal('show');
})
$('.getstartedbtn').click(function(){
	if($(this).attr('id') == 'gsbtn2')
	{
		var name = $('#name2').val();
		var email = $('#email2').val();
		var phone = $('#phone2').val();
		var msg = $('#msg2').val();
	}
	else
	{
		var name = $('#name1').val();
		var email = $('#email1').val();
		var phone = $('#phone1').val();
		var msg = $('#msg1').val();
	}
	if(name == '')
	{
		alert('Whoops, it looks like you forgot to enter your name');
	}
	else if(email == '')
	{
		alert('Whoops, it looks like you forgot to enter your email');
	}
	else{
		$('#sendmsg').modal('show');
		$.ajaxSetup({
	    headers: {
	        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
	    }
		});
		$.ajax(
		{
			type: 'POST',
			url: "/sendmail",
			data: {name: name, message: msg, email: email, phone: phone},
			success: function(data)
			{
				$('.form-contactus').val('');
				$('#sendmsg').modal('hide');
			}
		})
	}
})
